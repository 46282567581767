import React, { useState, useEffect } from 'react';

type RichButtonProps = {
  children?: any;
  active?: boolean;
  small?: boolean;
  icon?: string;
  title?: string;
  help?: string;
  clickFun: any;
  value?: string;
  disabled?: boolean;
  capitalize?: boolean;
  classes?: string;
};

export const RichButton: React.FC<RichButtonProps> = ({
  children,
  active,
  icon,
  title,
  help,
  clickFun,
  value,
  disabled,
  capitalize,
  classes,
  small,
}) => (
  <div
    className={`btn_rich ${active && 'active'} ${small ? 'small' : ''} ${
      disabled && 'opacity_7'
    } ${classes}`}
    onClick={!disabled ? clickFun : undefined}
    data-value={value}
  >
    {children}
    <i className={icon}></i>
    <p className={capitalize ? 'text-capitalize' : ''}>{title}</p>
    <p className="text-help">{help}</p>
  </div>
);

const CardsIcons = () => (
  <div className="cards_icons">
    <i className="fab fa-cc-mastercard"></i>
    <i className="fab fa-cc-visa"></i>
    <i className="fab fa-cc-amex"></i>
  </div>
);

export const WhatsappIcon = () => (
  <a
    href="https://wa.me/526141786108"
    target="_blank"
    rel="noopener noreferrer"
    className="btn_whatsaap fixed"
  >
    <i className="fab fa-whatsapp"></i>
  </a>
);

type SharebuttonProps = {
  text: string;
  url: string;
  title: string;
};

export const Sharebutton: React.FC<SharebuttonProps> = ({
  text,
  url,
  title,
}) => {
  const [buttonContent, setButtonContent] = useState<JSX.Element | string>(
    <i className="far fa-share-square"></i>
  );

  const share = () => {
    if (navigator.share) {
      navigator.share({
        text,
        url,
        title,
      });
    } else {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setButtonContent('Enlace copiado');
          setTimeout(() => {
            setButtonContent(<i className="far fa-share-square"></i>);
          }, 1000);
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    }
  };

  return (
    <button onClick={share} className="btn_share">
      {buttonContent}
    </button>
  );
};
